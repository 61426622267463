.container {
  min-width: calc(var(--offset-base-four-by-ten) * 3);
  display: flex;
  padding-left: calc(var(--offset-base-four) * 5);
}

.container_with_image {
  min-width: calc(var(--offset-base-four-by-ten) * 3);
  display: flex;
  align-items: center;
  gap: calc(var(--offset-base-four) * 2);
}

.item_green {
  position: relative;
}

.item_white {
  position: relative;
}

.item_green::before {
  content: "";
  width: calc(var(--offset-base-two) * 4);
  height: calc(var(--offset-base-two) * 4);
  border-radius: 50%;
  background-color: var(--text-accent-color);
  position: absolute;
  left: -20px;
  top: calc(50% - 4px);
}

.item_white::before {
  content: "";
  width: calc(var(--offset-base-two) * 4);
  height: calc(var(--offset-base-two) * 4);
  border-radius: 50%;
  background-color: var(--text-primary-color);
  position: absolute;
  left: -20px;
  top: calc(var(--offset-base-two) * 3);
}

.image {
  width: calc(var(--offset-base-two) * 12);
  height: calc(var(--offset-base-two) * 12);
}

@media screen and (max-width: 1040px) {
  .container_with_image {
    min-width: calc(var(--offset-base-four-by-ten) * 2);
    margin-left: -22px;
    gap: calc(var(--offset-base-two) * 3);
  }
  .image {
    width: calc(var(--offset-base-two) * 8);
    height: calc(var(--offset-base-two) * 8);
  }
}

@media screen and (max-width: 680px) {
  .container_with_image {
    min-width: calc(var(--offset-base-four-by-ten) * 3);
    gap: calc(var(--offset-base-four) * 2);
  }
  .image {
    width: calc(var(--offset-base-two) * 12);
    height: calc(var(--offset-base-two) * 12);
  }
}
