.container {
  display: flex;
  gap: calc(var(--offset-base-four) * 3);
}

.icon_wrapper {
  width: calc(var(--offset-base-two) * 15);
  display: flex;
  justify-content: center;
}

.icon {
  width: calc(var(--offset-base-two) * 15);
  height: calc(var(--offset-base-two) * 15);
}

.text_wrapper {
  display: flex;
  flex-direction: column;
}
