.header {
  grid-area: header;
  height: calc(var(--offset-base-four) * 25);
  width: calc(100% - 200px);
  max-width: calc(var(--offset-base-four-by-ten) * 42);
  min-width: calc(var(--offset-base-four-by-ten) * 21);
  padding: calc(var(--offset-base-four) * 2) 0px;
  background-color: var(--background-dark-color);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
}
.content {
  grid-area: content;
  width: 100%;
  max-width: calc(var(--offset-base-four-by-ten) * 42);
  min-width: calc(var(--offset-base-four-by-ten) * 21);
  margin-top: calc(var(--offset-base-four) * 25);
  display: flex;
  flex-direction: column;
}
.sections_container {
  max-height: calc(100vh - (calc(var(--offset-base-four) * 25)));
  padding-right: calc(var(--offset-base-four) * 4);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.wrapper_mobile_hidden {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1040px) {
  .header {
    width: calc(100% - 160px);
    max-width: calc(var(--offset-base-four-by-ten) * 22);
    min-width: calc(var(--offset-base-four-by-ten) * 16);
    height: calc(var(--offset-base-four) * 20);
  }
  .content {
    max-width: calc(var(--offset-base-four-by-ten) * 22);
    min-width: calc(var(--offset-base-four-by-ten) * 16);
    margin-top: calc(var(--offset-base-four) * 20);
    padding: calc(var(--offset-base-four) * 6) 0px;
  }
  .sections_container {
    max-height: calc(100vh - 98px);
  }
}

@media screen and (max-width: 800px) {
  .header {
    width: calc(100% - 80px);
    max-width: calc(var(--offset-base-four-by-ten) * 18);
    min-width: calc(var(--offset-base-four-by-ten) * 15);
  }
  .content {
    max-width: calc(var(--offset-base-four-by-ten) * 18);
    min-width: calc(var(--offset-base-four-by-ten) * 15);
  }
  .wrapper_mobile_hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .header {
    width: calc(100% - 40px);
    max-width: calc(var(--offset-base-four-by-ten) * 16);
    min-width: calc(var(--offset-base-four-by-ten) * 7);
    height: calc(var(--offset-base-four) * 12);
  }
  .content {
    max-width: calc(var(--offset-base-four-by-ten) * 16);
    min-width: calc(var(--offset-base-four-by-ten) * 7);
    margin-top: calc(var(--offset-base-four) * 12);
    padding: calc(var(--offset-base-four) * 4) 0px;
  }
  .sections_container {
    max-height: 100%;
    padding-right: 0px;
  }
}
