/* width */
.w-full {
  width: 100%;
}

/* height */
.h-full {
  height: 100%;
}

/* max-width */
.max-w-1 {
  max-width: var(--offset-base-four-by-ten);
}
.max-w-2 {
  max-width: calc(var(--offset-base-four-by-ten)  * 2);
}
.max-w-3 {
  max-width: calc(var(--offset-base-four-by-ten)  * 3);
}
.max-w-4 {
  max-width: calc(var(--offset-base-four-by-ten)  * 4);
}
.max-w-5 {
  max-width: calc(var(--offset-base-four-by-ten)  * 5);
}
.max-w-6 {
  max-width: calc(var(--offset-base-four-by-ten)  * 6);
}
.max-w-7 {
  max-width: calc(var(--offset-base-four-by-ten)  * 7);
}
.max-w-8 {
  max-width: calc(var(--offset-base-four-by-ten)  * 8);
}
.max-w-9 {
  max-width: calc(var(--offset-base-four-by-ten)  * 9);
}
.max-w-10 {
  max-width: calc(var(--offset-base-four-by-ten)  * 10);
}
