:root {
	--offset-base-two: 2px;
	--offset-base-four: 4px;
	--offset-base-four-by-ten: 40px;

	--background-dark-color: #142a36;
	--background-medium-color: #203640;
	--background-light-color: #2e3f4a;

	--border-color: #5c6871;

	--text-primary-color: #ffffff;
	--text-inactive-color: #5c6871;
	--text-accent-color: #218480;

	--common-transition: all .3s ease-in;
}

.bg-gradient_type_medium {
  background-image: linear-gradient(90deg, var(--background-dark-color), var(--background-medium-color));
}

.bg-gradient_type_light {
  background-image: linear-gradient(90deg, var(--background-medium-color), var(--background-light-color));
}

/* Custom-scroll classes */
.custom-scroll::-webkit-scrollbar {
	width: calc(var(--offset-base-two) * 6);
}
.custom-scroll::-webkit-scrollbar-track {
	background: var(--background-medium-color);
}
.custom-scroll::-webkit-scrollbar-thumb {
	background: var(--background-light-color);
	border: 3px solid var(--background-medium-color);
	border-radius: calc(var(--offset-base-two) * 3);
}

/* Container classes */
.container {
	margin: 0;
	padding: 0;	
  box-sizing: border-box;
}

.list {
  list-style-type: none;
}

.image {
  display: block;
  object-fit: cover;
  object-position: center;
}

/* Typography classes */
.text {
	margin: 0;
	padding: 0;
}

.text_type_align-center {
  text-align: center;
  text-wrap: balance;
}

.text_type_normal {
	font-family: 'Gotham Pro';
	font-weight: normal;
}

.text_type_bold {
	font-family: 'Gotham Pro';
	font-weight: bold;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


@media screen and (max-width: 680px) {
	.custom-scroll::-webkit-scrollbar {
		width: 0px;
	}
}
