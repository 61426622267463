.tabs_panel_desk {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: calc(var(--offset-base-four) * 5);
}

.tabs_panel_mob {
  display: none;
}

@media screen and (max-width: 800px) {
  .tabs_panel_desk {
    display: none;
  }

  .tabs_panel_mob {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: calc(var(--offset-base-four) * 5);
  }
}
