:root {
	scrollbar-color: var(--background-medium-color) var(--background-light-color);
	scrollbar-width: 12px;
}

@-moz-document url-prefix() {
	:root {
		scrollbar-color: var(--background-light-color) var(--background-medium-color);
	}
}

body {
	margin: 0 auto;
  background-color: var(--background-dark-color);
	color: var(--text-primary-color);
  display: flex;
  justify-content: center;
}

body::-webkit-scrollbar {
	width: 12px;
}
body::-webkit-scrollbar-track {
	background: var(--background-medium-color);
}
body::-webkit-scrollbar-thumb {
	background: var(--background-light-color);
	border: 3px solid var(--background-medium-color);
	border-radius: 6px;
}

#root {
  width: 100%;
  min-width: 320px;
  max-width: 1880px;
  padding: 0px 100px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

@media screen and (max-width: 1040px) {
  #root {
    padding: 0px 80px;
  }
}

@media screen and (max-width: 800px) {
  #root {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 680px) {
  #root {
    padding: 0px 80px;
  }
}

@media screen and (max-width: 425px) {
  #root {
    padding: 0px 20px;
  }
}
