.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  padding-top: calc(var(--offset-base-four) * 20);
  display: flex;
  justify-content: space-between;
  font-size: calc(var(--offset-base-two) * 10);
  line-height: 1.25;
}

@media screen and (max-width: 680px) {
  .wrapper {
    padding-top: calc(var(--offset-base-four) * 10);
    flex-direction: column;
    gap: calc(var(--offset-base-four) * 5);
    font-size: calc(var(--offset-base-two) * 8);
  }
}
