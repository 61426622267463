.container {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(4, minmax(calc(var(--offset-base-four-by-ten) * 5), 1fr));
  gap: calc(var(--offset-base-four) * 4);
}

@media screen and (max-width: 1040px) {
  .container {
    grid-template-rows: repeat(2, calc(var(--offset-base-four-by-ten) * 15));
    grid-template-columns: repeat(2, minmax(calc(var(--offset-base-four-by-ten) * 6), 1fr));
  }
}

@media screen and (max-width: 680px) {
  .container {
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: minmax(calc(var(--offset-base-four-by-ten) * 6), 1fr);
    gap: calc(var(--offset-base-four) * 15);
  }
}
