.container {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(var(--offset-base-four) * 10);
}

.heading_wrapper {
  height: calc(var(--offset-base-four-by-ten) * 8);
  display: flex;
  flex-direction: column;
  gap: calc(var(--offset-base-four) * 3);
}

.image {
  height: calc(var(--offset-base-four-by-ten) * 5);
}

.title_wrapper {
  display: flex;
  font-size: calc(var(--offset-base-two) * 13);
  line-height: 1.25;
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--offset-base-four) * 4);
}

.subtitle {
  font-size: calc(var(--offset-base-two) * 10);
  line-height: 1.25;
}


@media screen and (max-width: 1040px) {
}

@media screen and (max-width: 680px) {
  .heading_wrapper {
    height: max-content;
    padding-bottom: calc(var(--offset-base-four) * 15);
  }
}
