.container {
  display: flex;
  gap: calc(var(--offset-base-four) * 10);
}
.cards_wrapper {
  width: calc(50% - (var(--offset-base-four) * 10));
  min-width: calc(var(--offset-base-four-by-ten) * 10);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--offset-base-four) * 8);
}
.wrapper {
  display: grid;
  grid-template-rows:calc(var(--offset-base-four-by-ten) * 6);
  grid-template-columns: repeat(2, 1fr);
  gap: calc(var(--offset-base-four) * 8);
}

@media screen and (max-width: 1040px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: calc(var(--offset-base-four) * 10);
  }
  .cards_wrapper {
    width: 100%;
    max-width: calc(var(--offset-base-four-by-ten) * 15);
  }
  .wrapper {
    gap: calc(var(--offset-base-four) * 10);
  }
}

@media screen and (max-width: 680px) {
  .wrapper {
    grid-template-rows: repeat(2, max-content) ;
    grid-template-columns: minmax(calc(var(--offset-base-four-by-ten) * 7), 1fr);
    gap: calc(var(--offset-base-four) * 10);
  }
}
