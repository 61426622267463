.tab {
  min-width: min-content;
  padding: calc(var(--offset-base-four) * 4);
  cursor: pointer;
  color: var(--text-inactive-color);
  text-align: center;
  transition: var(--common-transition);
}

.tab:hover {
  color: var(--text-primary-color);
}

.tab_type_current {
  pointer-events: none;
  color: var(--text-primary-color);
}

.text {
  font-size: calc(var(--offset-base-two) * 10);
  line-height: 1.5;
}


@media screen and (max-width: 1040px) {
  .tab {
    min-width: calc(var(--offset-base-four-by-ten) * 2);
    max-width: calc(var(--offset-base-four-by-ten) * 4);
    padding: calc(var(--offset-base-four) * 3) calc(var(--offset-base-four) * 3);
  }

  .text {
    font-size: calc(var(--offset-base-two) * 8);
  }
}

@media screen and (max-width: 680px) {
  .tab {
    min-width: calc(var(--offset-base-four) * 18);
    max-width: calc(var(--offset-base-four) * 26);
    padding: calc(var(--offset-base-four) * 2) calc(var(--offset-base-four) * 2);
  }

  .text {
    font-size: calc(var(--offset-base-two) * 6);
  }
}
